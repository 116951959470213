import React, { useEffect, useRef, useState } from 'react';
import ProjectItem from './ProjectItem';
import Box from '../box/box';
import { Flipper } from 'react-flip-toolkit';
import styled from 'styled-components';
import usePagination from '../../hooks/usePagination';
import useSwipe from '../../hooks/useSwipe';
import PaginationControls from '../PaginationControls';

const ProjectList = ({ projects, onProject, selected }) => {
  const [mounted, setMounted] = useState(false);
  const [projectsPerPage, setProjectsPerPage] = useState(5);
  const { data, ...controlProps } = usePagination(projectsPerPage, projects);

  const swipeProps = useSwipe({
    onLeftSwipe: controlProps.previousPage,
    onRightSwipe: controlProps.nextPage,
  });

  const rootRef = useRef();

  useEffect(() => {
    console.log('gogo');
    if (window) {
      window.onhashchange = () => onProject();
    }

    setProjectsPerPage(Math.floor((rootRef.current.offsetHeight - 70) / 70));
  }, [rootRef.current]);
  console.log('render');

  const handleComplete = () => setMounted(prevState => !prevState);
  return (
    <div ref={rootRef}>
      <RootWrapper full>
        <Flipper
          flipKey={!!selected}
          spring="stiff"
          onComplete={handleComplete}
        >
          {selected ? (
            <ProjectItem
              onClick={() => mounted && onProject()}
              project={selected}
              selected={!!selected}
              mounted={mounted}
            />
          ) : (
            <div {...swipeProps}>
              {data.map((project, i) => {
                return (
                  <ProjectItem
                    key={i}
                    onClick={project => {
                      if (!mounted) onProject(project);
                    }}
                    project={project}
                    selected={selected === project.title}
                  />
                );
              })}
              <StyledPaginationControls {...controlProps} />
            </div>
          )}
        </Flipper>
      </RootWrapper>
    </div>
  );
};

const StyledPaginationControls = styled(PaginationControls)`
  display: flex;
  justify-content: center;
`;

const RootWrapper = styled(Box)`
  margin-top: 1rem;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
`;

export default ProjectList;
