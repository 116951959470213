import React from 'react';
import Box from '../box/box';
import BoldLabel from '../BoldLabel';
import GalleryGrid from './grid';
import styled from 'styled-components';

const HR = styled.hr`
  border: none;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid cyan;
  box-shadow: 0 20px 20px -20px aqua;
  margin: -50px auto 1rem;
`;

const Visuals = () => {
  return (
    <RootWrapper full>
      <BoldLabel>Some of my designs, mock ups and other things.</BoldLabel>
      <HR />

      <GalleryWrapper className="gallery">
        <GalleryGrid />
      </GalleryWrapper>
    </RootWrapper>
  );
};

const RootWrapper = styled(Box)`
  height: 90%;
`;

const GalleryWrapper = styled.div`
  overflow-y: auto;
  height: 56vh;
`;

export default Visuals;
