import React from 'react';
import Nav from './Nav';
// import LanguageSelector from './LanguageSelector';
import styled from 'styled-components';

const RootWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
`;

const ContentWrapper = styled.div`
  margin: 0 2em;
  @media (max-width: 500px) {
    margin: 0 1em;
  }
`;

export const AppBar = () => (
  <RootWrapper>
    <ContentWrapper>
      <Nav />
    </ContentWrapper>
  </RootWrapper>
);

export default AppBar;
