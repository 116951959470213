import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import LBW, { SRLWrapper } from 'simple-react-lightbox';

const ThumbGrid = ({ images }) => {
  return (
    <LBW>
      <SRLWrapper
        options={{
          buttons: {
            showDownloadButton: false,
            showAutoplayButton: false,
            showThumbnailsButton: false,
          },
        }}
      >
        <RootWrapper>
          {images.map((image, i) => (
            <Thumbnail fluid={image.node.childImageSharp.fluid} key={i} />
          ))}
        </RootWrapper>
      </SRLWrapper>
    </LBW>
  );
};

const RootWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: 4px;

  @media (max-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Thumbnail = styled(Img)`
  width: 100%;
  height: 100%;
  max-height: 200px;
`;

export default ThumbGrid;
