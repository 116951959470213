import React from 'react';
import styled, { withTheme } from 'styled-components';
import Divider from './Divider';

const Ul = styled.ul`
  display: flex;
  align-items: center;
`;

const Li = styled.li`
  &.active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const A = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;

  @media (max-width: 500px) {
    font-size: 3.4vw;
  }
`;

export const Nav = () => {
  return (
    <Ul id="menu">
      <Li data-menuanchor="about">
        <A href="#about">ABOUT</A>
      </Li>

      <Divider />

      <Li data-menuanchor="projects">
        <A href="#projects">PROJECTS</A>
      </Li>

      <Divider />

      <Li data-menuanchor="visuals">
        <A href="#visuals">VISUAL</A>
      </Li>
    </Ul>
  );
};

export default withTheme(Nav);
