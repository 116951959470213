import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.8em;
  margin-top: 1em;
  padding: 0 0.3em;
`;

const Divider = props => <P {...props}> | </P>;

export default Divider;
