import React from 'react';
import styled from 'styled-components';
import Github from '../../images/github.svg';
import Linkedin from '../../images/linkedin.svg';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  position: fixed;
  bottom: 0;
  z-index: 999;
`;

const ContentWrapper = styled.div`
  margin: 0 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
`;

const SocialIconWrapper = ({ url, Icon }) => (
  <a href={url} target="__blank" style={{ marginLeft: 6 }}>
    {Icon}
  </a>
);

SocialIconWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  Icon: PropTypes.element.isRequired,
};

export const Footer = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <SocialIconWrapper
          url="https://www.linkedin.com/in/lauri-meltti-32704112b"
          Icon={<Linkedin style={{ width: 30 }} />}
        />
        <SocialIconWrapper
          url="https://github.com/krokodiili"
          Icon={<Github style={{ width: 30 }} />}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Footer;
