import styled from 'styled-components';

const BoldLabel = styled.h2`
  font-weight: bold;
  margin-right: 1rem;
  margin-bottom: 0.3rem;
  @media (max-width: 500px) {
    font-size: 1.2em;
  }
`;

export default BoldLabel;
