import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ src, ...props }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Img
            {...props}
            fluid={
              data.allImageSharp.edges.find(element => {
                // Match string after final slash
                return element.node.fluid.src.split('/').pop() === src;
              }).node.fluid
            }
          />
        );
      }}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
};

export default Image;
