import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { spring } from 'react-flip-toolkit';
import { graphql } from 'gatsby';
import ReactFullpage from '@fullpage/react-fullpage';
import AppBar from 'components/AppBar';
import Footer from 'components/Footer';
import Profile from '../components/Profile';
import Projects from '../components/Projects';
import Visuals from '../components/Gallery';
import { Helmet } from 'react-helmet';

const Index = ({ location }) => {
  const key = '51F1D8D2-F7594AE0-A1124012-4CDDEE2B';
  //const key = process.env.GATSBY_FULLPAGE_LICENCE_KEY;
  const [selected, setSelected] = useState();
  const [loaded, setLoaded] = useState(false);
  const navWrapperRef = useRef(null);

  useEffect(() => {
    const content = navWrapperRef.current;

    spring({
      config: 'noWobble',
      values: {
        opacity: selected ? [1, 0] : [0, 1],
      },
      onUpdate: ({ opacity }) => {
        if (content && content.style) content.style.opacity = opacity;
      },
    });
  }, [selected]);

  const handleFullpageLoad = () => {
    setLoaded(true);
  };

  if (key) {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>LM</title>
          <description>
            {
              // eslint-disable-next-line quotes
              "Lauri Meltti's portfolio & personal website"
            }
          </description>
          <meta name="og:image" content="https://meltti.com/bg.png" />
          <meta name="og:image:alt" content="Portfolio of Lauri Meltti" />
          <link rel="icon" type="image/svg" href="icon.svg" />
        </Helmet>
        <Layout
          style={{
            visibility: loaded ? '' : 'hidden',
          }}
        >
          <div
            style={{
              opacity: selected ? 1 : 0,
              zIndex: 999,
            }}
            ref={navWrapperRef}
          >
            <AppBar />
          </div>
          <ReactFullpage
            afterLoad={handleFullpageLoad}
            paddingTop="60px"
            paddingBottom="60px"
            licenseKey={key}
            menu="#menu"
            fixedElements="#footer"
            anchors={['about', 'projects', 'visuals']}
            normalScrollElements=".project-details,.gallery"
            render={() => {
              return (
                <ReactFullpage.Wrapper>
                  <div className="section">
                    <Profile />
                  </div>
                  <div className="section">
                    <Projects
                      projects={projectData}
                      onProject={setSelected}
                      selected={selected}
                    />
                  </div>
                  <div className="section">
                    <Visuals />
                  </div>
                </ReactFullpage.Wrapper>
              );
            }}
          />
          <Footer location={location} />
        </Layout>
      </div>
    );
  }

  return null;
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;

const projectData = [
  {
    title: 'Socfinder',
    description: 'Search engine for social workers',
    type: 'work',
    technologies: [
      'Typescript',
      'React',
      'Material UI',
      'Postman testing',
      'Thymeleaf',
      'Spring boot',
      'Java',
    ],
    body: `

Socfinder is sort of like Google for social workers to find the right services for their customers
in Finland and it is used by most of the big municipalities.

I've been leading the team that is responsible for developing this project at Holda Technologies.
Particularly, most of the front end part is made by me.
The core of this project was not made by us, but we were able to continue and improve from
where the previous developers left, and build a lot of new important features on it.

I'm very happy to work on this project since what Socfinder is doing is very important to the society.

The website itself is not made by us, but there are more details if you're interested.

    `,
    link: 'https://socfinder.com',
    image: '/socfinder.jpg',
  },
  {
    title: 'Podit',
    description: 'Podcast/audiobook platform with focus on self development',
    technologies: ['Typescript', 'React', 'React Native', 'PostgreSQL', 'GCP'],
    type: 'work',
    body: `
Podit is new and growing platform to listen podcasts and audiobooks.
It's focus is on delivering nutritious self development content for the user based on their own interests.
This is done by deep categorizing, quality content selection
and by learning about the listeners interests.

I joined to lead the development of Podit in the middle, couple months before the actual release.

My task was initially to help with the release and ensure the quality
of code and tech decisions. From that i transitioned to lead the development.

Besides leading the team and project management, I've worked fullstack on the web app, mobile app, API's and the cloud
services hosting it.

   `,
    link: 'https://podit.fi',
    image: '/podit.jpg',
  },

  {
    title: 'Konnichiwa',
    description: 'Mobile app, management panel & website for the restaurant',
    technologies: [
      'Javascript',
      'React',
      'React Native',
      'expo',
      'Next.js',
      'serverless',
      'DynamoDB',
      'TinaCMS',
      'AWS',
    ],
    type: 'work',
    body: `
Konnichiwa is a sushi buffet restaurant franchise.

They wanted me to make them a mobile app for their loyalty stamp
cards, table reservations, and coupons etc. for their regulars. Also they wished to have a new website.

I led the development of this project at Holda Technologies.
Most of the code & design were made by me.

I often find making plain websites not so exciting. The project was rather simple,
but it was interesting since I needed to build an app and website supporting multiple restaurants.  I am quite happy about the outcome considering the budget,
and the mobile app has been working well.

    `,
    link: 'https://konnichiwa.fi',
    image: '/konnichiwa.webp',
  },
  {
    title: 'Locorum',
    description: 'custom CRM/ERP solution',
    type: 'work',
    technologies: [
      'Typescript',
      'React',
      'Nest.js',
      'React-admin',
      'Jest',
      'PostgreSQL',
      'Material UI',
      'Docker',
      'AWS',
      'Lerna',
    ],
    body: `
Locorum is a company providing sales, customer acqusition and services for others.

They were mainly using spreadsheets for their process and management. They wanted us to make their
process more efficient and less error prone, so that it would be easier for them to track & analyze the results.

I studied their current methods and planned a custom solution to meet their needs.
They were very happy about the solution and I've heard that it's been working well.

I was leading this project and the team responsible for building it at Holda Technologies.
I mostly worked on the server side of things in this project.

    `,
    image: '/locorum.png',
  },
  {
    title: 'Workoven',
    technologies: ['Dart', 'Flutter', 'Material UI'],
    description: 'Time tracker for work',
    type: 'side',
    body: `
At my work we were using excel template for the monthly timesheets.
I was a bit bothered by that template as it always had some problems. Plus it was
annoying to fill up the template, so I came up with the idea of Workoven.

Back then, I also wanted to learn a new programming language and try something new so
I ended up learning flutter/dart in a practical way by doing Workoven as a mobile application with them.

Workoven provides you an easy way to track work time by either clocking in/out or filling the hours separately.
It saves all your monthly timesheets with PDF format locally to your phone, so that you can easily send the hours to people taking care of salary matters.
It has built in pomodoro timer and you can also mark vacations, public holidays and sick leaves.

The app could still be polished for more general use and it lacks localization for proper public release,
but it does the job it was made for. I am unlikely going to continue this project much more further, as there are already some good existing paid solutions and I ended up
not being so huge fan of flutter, dart and their ecosystem.

The app can be found from the Google play store.
    `,
    link: 'https://play.google.com/store/apps/details?id=workoven.yeah',
    image: '/workoven.jpg',
  },
  {
    title: 'thePlayer',
    type: 'Work',
    technologies: ['Javascript', 'Loopback.js', 'Electron', 'Socket.io', 'S3'],
    image: '/tp.png',
    description: 'Remote media player controller',
    body: `
thePlayer was made for managing playing media in environments with multiple
devices playing different things, ie: museums, shopping malls etc.

It allows you to upload videos and images, create playlists out of them, assign
playlists for different devices, combine multiple playlists
and it even lets you time the change of playlists

the app used in the playing devices can also show the metadata of the media,
like the title, remaining playtime and next thing in the queue.

I was leading the development for this contract project at Vertics.

The project was in use in some remarkable places like Amos Rex.
    `,
  },
  {
    title: 'Boost',
    technologies: ['Typescript', 'React', 'MongoDB', 'GraphQL'],
    // eslint-disable-next-line quotes
    description: `Finland's fastest growing business network`,
    type: 'work',
    body: `
Boost is network where entrepreneurs and their representatives connect and
help eachother out. We built them a system which will help scaling their business
and offer utilities to support the needs of the community and their events.

I was leading the development at the start of the project and responsible
for making sure that they get quality stuff.

  `,
    link: 'https://boostfinland.fi',
    image: '/boost.png',
  },
  {
    title: 'Salkunhaltija',
    technologies: [
      'Typescript',
      'React',
      'Express',
      'PostgreSQL',
      'Cypress',
      'Jest',
      'Material UI',
    ],
    description: 'Digitalized property management',
    type: 'work',
    body: `
Managing properties, apartment leasing and tenants while documenting everything
is a lot of work. In Finland and many other countries, the process is still done
with extensive spreadsheets and even plain paper by some rather big companies.

Salkunhaltija's idea was to digitalize the whole process to lead to the efficiency.
It aimed to provide a clear structure and easy access for all the data related to the property management.

This was the main product of Holda Technologies, until the company pivoted
towards mainly building software as a service.

I've worked on multiple versions and variations of it as a fullstack developer, mostly on the
client side of things.
  `,
    link: 'https://salkunhaltija.fi/',
    image: '/shleiska.png',
  },
  {
    title: 'Muuttotarkastus.fi',
    technologies: ['Javascript', 'React', 'Jest'],
    description: 'Digitalized solution for apartment moving in/out inspections',
    type: 'work',
    body: `
This is Finland's first digitalized solution for apartment inspections.

Tenants very often make insufficient inspection when they check apartments for rent, and this project is of a great help in this case.

When you open the web application with your smart phone and input all the rooms of your apartment,
it will give you the guidance on what to inspect.
You can easily provide the images of the faults you find when inspecting.
Once you've done the inspection, the result will be sent directly to the landlord.

I was working on designing & developing the first versions of this app's UI.


  `,
    link: 'https://muuttotarkastus.fi',
    image: '/mt.png',
  },
  {
    title: 'Custom online store',
    technologies: ['Typescript', 'Next.js', 'Cypress', 'Docker'],
    description:
      'Online store tailored for supporting your favourite/local sport club with your orders',
    type: 'work',
    body: `
I was responsible for developing the front end for this project at Holda Technologies.
The store's standard features and feel was made similar to the core of verkkokauppa.com

  `,
    image: '/seurahankinta.png',
  },
  {
    title: 'browserSynth',
    image: '/qs.png',
    description: 'Synthesizer right in browser that you can play with keyboard',
    technologies: ['Javascript', 'React'],
    type: 'fun',
    body: `
I have musical background. And I wanted to do something fun and related to the browser,
while learning more about the world of web audio.

Inspired by the way you can play sounds with your computer keyboard in FL studio
without a MIDI one, I decided to do something to play with in the browser in a similar way.

`,
    link: 'https://friendly-ardinghelli-f2a61c.netlify.app',
  },

  {
    title: 'Anymetrics',
    technologies: ['Javascript', 'Gatsby.js', 'Netlify CMS'],
    description: 'Website',
    type: 'work',
    body: `
I helped them build a basic branding, then designed and coded
their website based on it. I also added custom CMS solution to provide an easy access
to the content editing.

`,
    link: 'https://www.anymetrics.fi/',
    image: '/anymetrics.png',
  },
  {
    title: 'Car platform',
    technologies: ['Typescript', 'Next.js', 'Nest.js', 'Puppeteer'],
    description: 'Search cars from german market and order them to Finland',
    type: 'work',
    body: `
I was leading the development of this project @ Holda Technologies and
mainly responsible for architecture and back end development while guiding the front end
development.
I developed ways to scrape the data from German auto markets,
translate them and we built search engine around it in synchronized way.

`,
    image: '/arcomi.png',
  },
  {
    title: 'Cleaner finder',
    technologies: ['Typescript', 'Next.js', 'Nest.js'],
    description: 'Platform to find suitable cleaner for your airbnb etc.',
    type: 'work',
    body: `
I was leading the development of this project @ Holda Technologies and
mainly responsible for architecture and back end development while guiding the front end
development and design.


`,
    image: '/aa.jpeg',
  },
  {
    title: 'Review totem',
    technologies: ['Svelte', 'Tensorflow', 'PWA'],
    description: 'Handsfree 1-5 reviewing by showing fingers to a camera',
    type: 'work',
    body: `
I was resposible for developing PoC of this idea @ Holda Technologies.
Idea was to have a totem screen with a camera that would detect when
someone approaches the screen and show instructions to show fingers from 1-5
to give a review of the ie. shopping experience.

The app would accurately detect it and wait for couple seconds before locking
review up, sending it for the server and giving thanks for the reviewer.

Although JS and the browser are not the most optimal technologies to
build this kind of solution, it worked out fairly well and the performance
was fine, since the totems had ok specs.
`,
    image: '/ht.png',
  },
];
