import React from 'react';
import styled from 'styled-components';
import Arrow from '../images/arrow.svg';

export default function PaginationControls({
  nextPage,
  previousPage,
  currentPage,
  pageAmount,
  ...props
}) {
  if (!pageAmount || pageAmount === 1) {
    return <div />;
  }

  return (
    <RootWrapper {...props}>
      <PaginationArrow onClick={previousPage} disabled={currentPage === 1} />
      {Array.from(Array(pageAmount)).map((_, idx) => {
        return <PageBall active={currentPage === idx + 1} key={idx} />;
      })}
      <PaginationArrow
        onClick={nextPage}
        right
        disabled={currentPage === pageAmount}
      />
    </RootWrapper>
  );
}

const PaginationArrow = ({ onClick, right, disabled }) => {
  return (
    <ArrowWrapper
      role="button"
      onClick={disabled ? null : onClick}
      onKeyPress={onClick}
      tabIndex="0"
    >
      <StyledArrow right={right ? 'true' : ''} disabled={disabled} />
    </ArrowWrapper>
  );
};

const RootWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowWrapper = styled.span`
  :focus {
    outline: 0;
  }
  cursor: pointer;
`;

const StyledArrow = styled(Arrow)`
  width: 30px;
  margin: 1rem;
  fill: ${({ disabled, theme }) => (disabled ? '#888' : theme.colors.primary)};
  transform: ${({ right }) => (right ? 'rotate(180deg)' : '')};
`;

const PageBall = styled.div`
  margin: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : '#333'};
`;
