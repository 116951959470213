import { useState } from 'react';

export default (itemsPerPage, data) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageAmount = Math.ceil(data.length / itemsPerPage);

  return {
    currentPage: currentPage + 1,
    data: [...data].splice(itemsPerPage * currentPage, itemsPerPage),
    nextPage: () =>
      currentPage < pageAmount - 1 && setCurrentPage(currentPage + 1),
    previousPage: () => currentPage > 0 && setCurrentPage(currentPage - 1),
    pageAmount,
  };
};
