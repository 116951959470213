import React, { useRef, useEffect } from 'react';
import { spring } from 'react-flip-toolkit';
import BoldLabel from '../BoldLabel';
import styled, { keyframes } from 'styled-components';
import { Flipped } from 'react-flip-toolkit';

const ProjectItem = ({ project, onClick, selected, mounted, ...rest }) => {
  console.log('joppis');
  const { title, description } = project;
  const contentRef = useRef(null);
  const buttonRef = useRef(null);

  const ContentWrapper = styled.div`
    overflow: hidden;

    height: auto;
    opacity: 0;

    display: flex;
    margin-top: 0.8rem;
    margin-bottom: 2rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  `;

  const handleClick = () => {
    if (selected) {
      const content = contentRef.current;
      const closeBtn = buttonRef.current;

      spring({
        config: 'stiff',
        values: {
          translateY: [0, -20],
          opacity: [1, 0],
        },
        onUpdate: ({ translateY, opacity }) => {
          if (content) {
            content.style.opacity = opacity;
            content.style.transform = `translateY(${translateY}px)`;
            closeBtn.style.opacity = opacity;
          }
        },
        onComplete: () => {
          // add callback logic here if necessary
          onClick(null);
        },
      });
    } else {
      onClick(project);
    }
  };

  useEffect(() => {
    if (mounted && selected) {
      const content = contentRef.current;
      const closeBtn = buttonRef.current;
      spring({
        config: 'wobbly',
        values: {
          translateY: [-20, 0],
          opacity: [0, 1],
        },
        onUpdate: ({ translateY, opacity }) => {
          content.style.opacity = opacity;
          closeBtn.style.opacity = opacity;

          content.style.transform = `translateY(${translateY}px)`;
        },
        delay: 200,
        onComplete: () => {
          // add callback logic here if necessary
        },
      });
    }
  }, [mounted, selected]);

  return (
    <RootWrapper
      selected={selected}
      className={selected ? 'project-details' : ''}
      {...rest}
    >
      <Flipped flipId={project.title}>
        <div>
          <HeaderWrapper>
            <TitleWrapper onClick={handleClick}>
              <BoldLabel>{title.toUpperCase()}</BoldLabel>
              <h6>{description}</h6>
            </TitleWrapper>
            {selected ? (
              <CloseButton
                ref={buttonRef}
                onClick={handleClick}
                onKeyPress={handleClick}
                role="button"
                className="close-btn"
                tabIndex={0}
              >
                <img
                  alt="close"
                  src="https://img.icons8.com/clouds/60/000000/close-window.png"
                ></img>
              </CloseButton>
            ) : (
              <h5>{project.type.toUpperCase()}</h5>
            )}
          </HeaderWrapper>
          <hr />
        </div>
      </Flipped>

      {selected && mounted && (
        <ContentWrapper ref={contentRef}>
          <DetailsWrapper>
            <p>{project.body}</p>
            <Row>
              {project.technologies?.map(tech => {
                return <h6 key={tech}>{tech}</h6>;
              })}
            </Row>
            {project.link && (
              <a href={project.link} target="__blank">
                Link to the project
              </a>
            )}
          </DetailsWrapper>

          <ProjectPicture src={project.image} alt="project" />
        </ContentWrapper>
      )}
    </RootWrapper>
  );
};

export default ProjectItem;

const colorChange = ({ from, to }) => keyframes`
from {
    color: ${from}
  }

  to {
    color: ${to}
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  h6 {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
`;
const CloseButton = styled.div`
  opacity: 0;
`;

const RootWrapper = styled.div`
  ${({ selected, theme }) => {
    if (selected) {
      return `
      position: absolute;
      padding: 1em;
      top: 0;
      left: 0;
      right: 0;
      bottom: 20px;
      overflow-y: auto;
      h2 {
        color ${theme.colors.primary}
      }
      h6 {
        color: #999;
  }

      `;
    }
  }}

  &:hover {
    h2 {
      animation: ${({ theme }) =>
          colorChange({
            from: '#fff',
            to: theme.colors.primary,
          })}
        0.3s linear forwards;
    }

    h6 {
      animation: ${() =>
          colorChange({
            from: '#fff',
            to: '#999',
          })}
        0.3s linear forwards;
    }
  }
`;

const ProjectPicture = styled.img`
  max-width: 600px;
  max-height: 500px;

  @media screen and (max-width: 500px) {
    max-width: 100%;
  }
`;

const DetailsWrapper = styled.pre`
  max-width: 700px;
  font-size: 1.1rem;
  white-space: pre-wrap;
  p {
    margin-bottom: 0.5rem;
  }
  margin-bottom: 0.5rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  height: 60px;
  margin-bottom: 0.5em;
`;

const TitleWrapper = styled.div`
  width: 100%;
`;
