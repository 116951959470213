import React from 'react';
import Box from '../box/box';
import styled, { keyframes } from 'styled-components';
import Image from '../Image';
import BoldLabel from '../BoldLabel';
export const Profile = () => (
  <Box>
    <RootWrapper>
      <ProfileImage src="me.jpg" />

      <Divider />

      <Details>
        <Row>
          <Title> Lauri Meltti </Title>
          <Assignment> Software Engineer </Assignment>
        </Row>
        <Row>
          <Facts>
            <FactRow>
              <FactsLabel> Status: </FactsLabel>
              Working as software consultant at Brightly
            </FactRow>
          </Facts>
        </Row>
        <Description>
          <Row>
            Passionate developer with loads of experience of building fullstack
            web application solutions and a lot of interest to creative coding.
          </Row>

          <Row>
            I have a broad set of skills and have designed, planned and led
            multiple customer projects successfully.
          </Row>

          <Row>
            My understanding of business behind the code is pretty decent. I
            enjoy building interactive stuff and quality solutions i can be
            proud of.
          </Row>
        </Description>
        <MotorcycleWrapper>
          <StyledMotorcycle src="mc.gif" alt="motorcycle" />
          <TextWrapper>
            <GlitchRow data-text="Javascript creativity simplicity challenges">
              Javascript creativity simplicity challenges
            </GlitchRow>
          </TextWrapper>
        </MotorcycleWrapper>
      </Details>
    </RootWrapper>
  </Box>
);

export default Profile;

const textKeyframes = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  width: 0;
  animation: ${textKeyframes} 3.2s linear forwards;
  animation-delay: 2s;
`;

const MotorcycleWrapper = styled.div`
  position: relative;
`;

const mcKeyframes = keyframes`
  0% {
    left: -2%;
  }
  100% {
    left: 89%;
    display:none;
  }
`;

const mcMobileKeyframes = keyframes`
  0% {
    left: -2%;
  }
  100% {
    left: 82%;
    display:none;
  }
`;

const StyledMotorcycle = styled.img`
  position: absolute;
  z-index: 909;
  animation: ${mcKeyframes} 3s linear forwards;
  left: -44px;
  top: -34px;
  animation-delay: 2s;
  width: 150px;
  @media (max-width: 500px) {
    width: 90px;
    left: -10px;
    top: -10px;
    animation: ${mcMobileKeyframes} 3s linear forwards;
    animation-delay: 2s;
  }
`;
const Row = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  @media (max-width: 500px) {
    margin-bottom: 0.5rem;
  }
`;
const glitch1 = keyframes`
  0% {
    clip: rect(132px, auto, 101px,0px);
  }
  5% {
    clip: rect(17px, auto, 94px, 0px);
  }
  10% {
    clip: rect(40px, auto, 66px, 0px);
  }
  15% {
    clip: rect(87px, auto, 82px, 0px);
  }
  20% {
    clip: rect(137px, auto, 61px, 0px);
  }
  25% {
    clip: rect(34px, auto, 14px, 0px);
  }
  30% {
    clip: rect(133px, auto, 74px, 0px);
  }
  35% {
    clip: rect(76px, auto, 107px, 0px);
  }
  40% {
    clip: rect(59px, auto, 10px, 0px);
  }
  45% {
    clip: rect(29px, auto, 84px, 0px);
  }
  50% {
    clip: rect(22px, auto, 67px, 0px);
  }
  55% {
    clip: rect(67px, auto, 62px, 0px);
  }
  60% {
    clip: rect(10px, auto, 105px, 0px);
  }
  65% {
    clip: rect(78px, auto, 115px, 0px);
  }
  70% {
    clip: rect(105px, auto, 13px, 0px);
  }
  75% {
    clip: rect(15px, auto, 75px, 0px);
  }
  80% {
    clip: rect(66px, auto, 39px, 0px);
  }
  85% {
    clip: rect(133px, auto, 73px, 0px);
  }
  90% {
    clip: rect(36px, auto, 128px, 0px);
  }
  95% {
    clip: rect(68px, auto, 103px, 0px);
  }
  100% {
    clip: rect(14px, auto, 100px, 0px);
  }
`;

const glitch2 = keyframes`
  0% {
    clip: rect(129px, auto, 36px, 0px);
  }
  5% {
    clip: rect(36px, auto, 4px, 0px);
  }
  10% {
    clip: rect(85px, auto, 66px, 0px);
  }
  15% {
    clip: rect(91px, auto, 91px, 0px);
  }
  20% {
    clip: rect(148px, auto, 138px, 0px);
  }
  25% {
    clip: rect(38px, auto, 122px, 0px);
  }
  30% {
    clip: rect(69px, auto, 54px, 0px);
  }
  35% {
    clip: rect(98px, auto, 71px, 0px);
  }
  40% {
    clip: rect(146px, auto, 34px, 0px);
  }
  45% {
    clip: rect(134px, auto, 43px, 0px);
  }
  50% {
    clip: rect(102px, auto, 80px, 0px);
  }
  55% {
    clip: rect(119px, auto, 44px, 0px);
  }
  60% {
    clip: rect(106px, auto, 99px, 0px);
  }
  65% {
    clip: rect(141px, auto, 74px, 0px);
  }
  70% {
    clip: rect(100px, auto, 78px, 0px);
  }
  75% {
    clip: rect(133px, auto, 79px, 0px);
  }
  80% {
    clip: rect(78px, auto, 52px, 0px);
  }
  85% {
    clip: rect(35px, auto, 39px, 0px);
  }
  90% {
    clip: rect(67px, auto, 70px, 0px);
  }
  95% {
    clip: rect(71px, auto, 103px, 0px);
  }
  100% {
    clip: rect(83px, auto, 40px, 0px);
  }
`;

const GlitchRow = styled.h3`
  position: relative;
  text-transform: uppercase;
  overflow: hidden;
  width: 100%;
  height: 1.5rem;
  word-wrap: none;
  font-size: 1.4rem;
  font-weight: 900;

  @media (max-width: 500px) {
    font-size: 3.2vw;
    height: 1rem;
    padding: 2rem 0 0 0;
  }
  letter-spacing: -1px;

  padding: 2rem 2rem 0 0;
  &:before,
  &:after {
    position: absolute;
    content: attr(data-text);
    padding: 2rem 2rem 2rem 0;
    top: 0;
    left: 0;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
  }

  &:before {
    left: 1px;
    text-shadow: -1px 1px rgba(255, 13, 191, 0.4);
    animation: ${glitch1} 3s ease-in reverse infinite;
  }

  &:after {
    left: -1px;
    text-shadow: -1px 1px rgba(255, 255, 255, 0.2);

    animation: ${glitch2} 3s ease-in-out reverse infinite;
  }
`;

const RootWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Divider = styled.div`
  margin: 1em;
  @media (max-width: 500px) {
    display: none;
  }
`;

const Description = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.5em;
`;

const Assignment = styled(BoldLabel)`
  display: flex;
  align-self: flex-end;
  margin-bottom: 0.28em;
  margin-left: 1em;
  @media (max-width: 500px) {
    display: none;
  }
`;

const Facts = styled.div``;

const FactRow = styled.div`
  display: flex;
  margin-bottom: 0.4em;
  align-items: center;
`;

const FactsLabel = styled(BoldLabel)`
  width: 60px;
`;

const ProfileImage = styled(Image)`
  width: 100%;
  max-width: 420px;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  @media (max-width: 500px) {
    display: none;
  }
`;

const Details = styled.div`
  max-width: 660px;
  @media (max-width: 660px) {
    margin-top: 0em;
    max-width: 100%;
  }
  margin-top: 1em;
`;
