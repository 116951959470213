import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import ThumbGrid from './thumbnails';

const GalleryComponent = () => {
  return (
    <StaticQuery
      query={graphql`
        query allImgQuery {
          source: allFile(filter: { absolutePath: { regex: "/gallery/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 1400) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const images = data.source.edges;
        return <ThumbGrid images={images} />;
      }}
    />
  );
};
export default GalleryComponent;
